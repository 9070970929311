<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h1 class="page-header">How to use this model</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h5>
              The following buttons are available throughout this budget impact model.
            </h5>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2">
            <button class="button">Nav</button>
            <p class="compressed">
              An orange button indicates that you may move back and forth between pages of this
              budget impact model.
            </p>
          </div>
          <div class="cell small-12 medium-2">
            <button class="button two-line blue-active"><span>Active</span>Tab</button>&nbsp;
            <button class="button two-line blue-inactive"><span>Inactive</span>Tab</button>
            <p class="compressed">
              A blue button indicates an active/inactive tab button. There may be more than one
              button that you can toggle between.
            </p>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2">
            <img width="30" src="../assets/menu_buttons/instruction_button.png" alt="" />
            <p class="pt-4 compressed">
              Click on the above symbol for instructions on how to use this programme.
            </p>
          </div>
          <div class="cell small-12 medium-2">
            <img width="30" src="../assets/menu_buttons/indication_button.png" alt="" />
            <p class="pt-4 compressed">
              The above symbol allows you to see the indications.
            </p>
          </div>
          <div class="cell small-12 medium-2">
            <img width="30" src="../assets/menu_buttons/ref_button.png" alt="" />
            <p class="pt-4 compressed">
              The above symbol allows you to navigate to the references page.
            </p>
          </div>
          <div class="cell small-12 medium-2">
            <img width="30" src="../assets/menu_buttons/menu_button.png" alt="" />
            <p class="pt-4 compressed">
              The above symbol allows you to open an additional menu.
            </p>
          </div>
          <div class="cell small-12 medium-2">
            <img width="30" src="../assets/menu_buttons/PI_button.png" alt="" />
            <p class="pt-4 compressed">
              The above symbol allows you to navigate to prescribing information.
            </p>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <hr class="wide black-line" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2">
            <h4>Cell key</h4>
          </div>
          <div class="cell small-12 medium-2">
            <input class="empty" type="text">
            <p class="compressed text-medium">
              <strong>Input Cell -</strong><br />
              No default assumption
            </p>
          </div>
          <div class="cell small-12 medium-2">
            <input class="default" type="text">
            <p class="compressed text-medium">
              <strong>Default Assumption -</strong><br />
              Can be overwritten
            </p>
          </div>
          <div class="cell small-12 medium-2">
            <input class="fixed" type="text" disabled>
            <p class="compressed text-medium">
              <strong>Fixed/Calculation Cell -</strong><br />
              Cannot be altered
            </p>
          </div>
          <div class="cell small-12 medium-2 medium-offset-2 pt-5 text-right">
            <router-link to="/population">
              <button class="button">Next</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'HowToUse',
  components: {
    Header,
  },
  computed: {
    hash() {
      return this.$store.state.hash;
    },
  },
  methods: {
    checkHashExists() {
      if (this.hash.length === 0) {
        this.$router.push('/inactive-link');
      }
    },
  },
  mounted() {
    this.checkHashExists();
  },
};
</script>
