var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_vm._m(0),_c('div',{staticClass:"bg-light-grey"},[_c('div',{staticClass:"grid-container"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"grid-x grid-padding-x"},[_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"cell small-12 medium-2 medium-offset-2 pt-5 text-right"},[_c('router-link',{attrs:{"to":"/population"}},[_c('button',{staticClass:"button"},[_vm._v("Next")])])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-mid-grey mt-5"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-x grid-padding-x"},[_c('div',{staticClass:"cell small-12 pt-5 pb-5"},[_c('h1',{staticClass:"page-header"},[_vm._v("How to use this model")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x grid-padding-x"},[_c('div',{staticClass:"cell small-12 pt-5 pb-5"},[_c('h5',[_vm._v(" The following buttons are available throughout this budget impact model. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x grid-padding-x"},[_c('div',{staticClass:"cell small-12 medium-2"},[_c('button',{staticClass:"button"},[_vm._v("Nav")]),_c('p',{staticClass:"compressed"},[_vm._v(" An orange button indicates that you may move back and forth between pages of this budget impact model. ")])]),_c('div',{staticClass:"cell small-12 medium-2"},[_c('button',{staticClass:"button two-line blue-active"},[_c('span',[_vm._v("Active")]),_vm._v("Tab")]),_vm._v("  "),_c('button',{staticClass:"button two-line blue-inactive"},[_c('span',[_vm._v("Inactive")]),_vm._v("Tab")]),_c('p',{staticClass:"compressed"},[_vm._v(" A blue button indicates an active/inactive tab button. There may be more than one button that you can toggle between. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x grid-padding-x"},[_c('div',{staticClass:"cell small-12 medium-2"},[_c('img',{attrs:{"width":"30","src":require("../assets/menu_buttons/instruction_button.png"),"alt":""}}),_c('p',{staticClass:"pt-4 compressed"},[_vm._v(" Click on the above symbol for instructions on how to use this programme. ")])]),_c('div',{staticClass:"cell small-12 medium-2"},[_c('img',{attrs:{"width":"30","src":require("../assets/menu_buttons/indication_button.png"),"alt":""}}),_c('p',{staticClass:"pt-4 compressed"},[_vm._v(" The above symbol allows you to see the indications. ")])]),_c('div',{staticClass:"cell small-12 medium-2"},[_c('img',{attrs:{"width":"30","src":require("../assets/menu_buttons/ref_button.png"),"alt":""}}),_c('p',{staticClass:"pt-4 compressed"},[_vm._v(" The above symbol allows you to navigate to the references page. ")])]),_c('div',{staticClass:"cell small-12 medium-2"},[_c('img',{attrs:{"width":"30","src":require("../assets/menu_buttons/menu_button.png"),"alt":""}}),_c('p',{staticClass:"pt-4 compressed"},[_vm._v(" The above symbol allows you to open an additional menu. ")])]),_c('div',{staticClass:"cell small-12 medium-2"},[_c('img',{attrs:{"width":"30","src":require("../assets/menu_buttons/PI_button.png"),"alt":""}}),_c('p',{staticClass:"pt-4 compressed"},[_vm._v(" The above symbol allows you to navigate to prescribing information. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x grid-padding-x"},[_c('div',{staticClass:"cell small-12"},[_c('hr',{staticClass:"wide black-line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-12 medium-2"},[_c('h4',[_vm._v("Cell key")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-12 medium-2"},[_c('input',{staticClass:"empty",attrs:{"type":"text"}}),_c('p',{staticClass:"compressed text-medium"},[_c('strong',[_vm._v("Input Cell -")]),_c('br'),_vm._v(" No default assumption ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-12 medium-2"},[_c('input',{staticClass:"default",attrs:{"type":"text"}}),_c('p',{staticClass:"compressed text-medium"},[_c('strong',[_vm._v("Default Assumption -")]),_c('br'),_vm._v(" Can be overwritten ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-12 medium-2"},[_c('input',{staticClass:"fixed",attrs:{"type":"text","disabled":""}}),_c('p',{staticClass:"compressed text-medium"},[_c('strong',[_vm._v("Fixed/Calculation Cell -")]),_c('br'),_vm._v(" Cannot be altered ")])])
}]

export { render, staticRenderFns }